<template>
  <div id="app">
    <nav>
  
      <router-link to="/buyers">Байера</router-link>
      <router-link to="/products">Продукты</router-link>
      <router-link to="/op">ОП</router-link>
      <router-link to="/influence">Influence</router-link>
    </nav>
    <router-view></router-view>
  </div>
</template>

<script>
export default {
  name: 'App',
};
</script>

<style>
#app{
  padding-left: 20px;
  padding-right: 20px;
}
nav {
  position: sticky;
  display: flex;
  gap: 40px;
  margin-bottom: 0px;
  padding-left:0px;
  z-index: 100;
  padding: 10px 0px;
  background: white;
  width: 100%;
  z-index: 1;
}

nav a {
  text-decoration: none;
  color: #1f2937;
}

nav a.router-link-exact-active {
  font-weight: bold;
}
</style>
