import { defineStore } from 'pinia';
import { fetchData } from '../api/api';
import { transformData } from "../opProcessor";

export const useOpStore = defineStore('opStore', {
  state: () => ({
    products: [],
    searchQuery: localStorage.getItem('searchQuery') || '', // Сохранение поиска
    selectedSort: localStorage.getItem('selectedSort') || 'none', // Сохранение сортировки
    currentPage: Number(localStorage.getItem('currentPage')) || 1 // Сохранение текущей страницы
  }),
  actions: {
    async loadProducts() {
      try {
        const data = await fetchData('op');
        this.products = transformData(data);
      } catch (error) {
        console.error('Error loading products:', error);
      }
    },
    // Метод для сохранения фильтров в localStorage
    saveFilters() {
      localStorage.setItem('searchQuery', this.searchQuery);
      localStorage.setItem('selectedSort', this.selectedSort);
      localStorage.setItem('currentPage', this.currentPage.toString());
    },
    // Применение сортировки
    applySort() {
      if (this.selectedSort === 'spendAsc') {
        this.products.sort((a, b) => a.prevDaySpend - b.prevDaySpend);
      } else if (this.selectedSort === 'spendDesc') {
        this.products.sort((a, b) => b.prevDaySpend - a.prevDaySpend);
      } else if (this.selectedSort === 'priceAsc') {
        this.products.sort((a, b) => a.prevDayPrice - b.prevDayPrice);
      } else if (this.selectedSort === 'priceDesc') {
        this.products.sort((a, b) => b.prevDayPrice - a.prevDayPrice);
      }
    }
  }
});
