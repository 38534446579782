<template>
  <div class="login-page">
    <h2>Login</h2>
    <form @submit.prevent="login">
      <table class="login-table">
        <tr>
          <td>
            <label for="username">Username:</label>
          </td>
          <td>
            <input type="text" v-model="username" id="username" required />
          </td>
        </tr>
        <tr>
          <td>
            <label for="password">Password:</label>
          </td>
          <td>
            <input type="password" v-model="password" id="password" required />
          </td>
        </tr>
        <tr>
          <td colspan="2" class="button-cell">
            <button type="submit">Login</button>
          </td>
        </tr>
      </table>
      <p v-if="error" class="error-message">{{ error }}</p>
    </form>
  </div>
</template>

<script setup>
import { ref } from 'vue';
import { useRouter } from 'vue-router';

const username = ref('');
const password = ref('');
const error = ref(null);
const router = useRouter();

async function login() {
  try {
    const response = await fetch('https://dashboard.saiditeam.com/login', {
      method: 'POST',
      headers: { 'Content-Type': 'application/json' },
      body: JSON.stringify({ username: username.value, password: password.value })
    });

    if (response.ok) {
      const data = await response.json();
      // Сохраняем JWT токен в localStorage
      localStorage.setItem('token', data.token);
      // Перенаправляем пользователя
      router.push('/buyers');
    } else {
      error.value = 'Invalid credentials';
    }
  } catch (err) {
    error.value = 'Error occurred during login';
  }
}
</script>

<style scoped>
/* Основной стиль для страницы */
.login-page {
  max-width: 400px;
  margin: 0 auto;
  padding: 20px;
  background-color: #f9f9f9;
  border-radius: 8px;
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
}

/* Стиль для таблицы формы */
.login-table {
  width: 100%;
  border-spacing: 10px;
}

/* Стиль для ячеек таблицы */
.login-table td {
  padding: 8px;
  vertical-align: middle;
}

/* Стиль для кнопки */
.button-cell {
  text-align: center;
}

/* Стиль для ошибок */
.error-message {
  color: red;
  text-align: center;
  margin-top: 10px;
}

/* Стиль для полей ввода */
input[type="text"], input[type="password"] {
  width: 100%;
  padding: 10px;
  border: 1px solid #ccc;
  border-radius: 4px;
  font-size: 14px;
}

/* Стиль для кнопки */
button[type="submit"] {
  width: 100%;
  padding: 10px;
  background-color: #4CAF50;
  color: white;
  border: none;
  border-radius: 4px;
  font-size: 16px;
  cursor: pointer;
  margin-top: 10px;
}

button[type="submit"]:hover {
  background-color: #45a049;
}
</style>
