import { defineStore } from 'pinia';
import { fetchData } from '../api/api';
import { cleanAndSplitArrayIntoChunks, transformData } from '../dataProcessor';

export const useUserStore = defineStore('userStore', {
  state: () => ({
    users: [],
  }),
  getters: {
    allProjects: (state) => {
      return state.users.reduce((acc, user) => {
        return acc.concat(user.projects);
      }, []);
    },
  },
  actions: {
    async loadUsers() {
      try {
        const rawData = await fetchData('fb-buyer');
        const chunkedData = cleanAndSplitArrayIntoChunks(rawData);
        this.users = transformData(chunkedData);
      } catch (error) {
        console.error('Error loading users:', error);
      }
    },
  },
});
