import { createRouter, createWebHistory } from 'vue-router';
import HomePage from '../components/HomePage.vue';
import ProductList from '../components/ProductList.vue';
import ProjectList from '@/components/ProjectList.vue';
import OpList from '@/components/OpList.vue';
import LoginPage from '@/components/LoginPage.vue'; // Импортируем компонент логина
import InfluencePage from '@/components/InfluencePage.vue';

// Определяем маршруты
const routes = [
  { path: '/', component: HomePage },
  { path: '/login', component: LoginPage }, // Страница логина
  {
    path: '/products',
    component: ProductList,
    meta: { requiresAuth: true }, // Защищённый маршрут
  },
  {
    path: '/buyers',
    component: ProjectList,
    meta: { requiresAuth: true }, // Защищённый маршрут
  },
  {
    path: '/op',
    component: OpList,
    meta: { requiresAuth: true }, // Защищённый маршрут
  },
  {
    path: '/influence',
    component: InfluencePage,
    meta: { requiresAuth: true }, // Защищённый маршрут
  },
];

const router = createRouter({
  history: createWebHistory(),
  routes,
});
const decodeToken = (token) => {
  try {
    return JSON.parse(atob(token.split('.')[1]));
  } catch (e) {
    console.error("Invalid token:", e);
    return null;
  }
};

router.beforeEach((to, from, next) => {
  const token = localStorage.getItem('token');

  if (token) {
    const decoded = decodeToken(token);
    let currentTime = Date.now() / 1000;
    currentTime += 170

    // Проверяем, истёк ли токен
    if (decoded.exp < currentTime) {
      localStorage.removeItem("token");
      next("/login");
    } else if (to.path === "/login") {
      next("/buyers");
    } else {
      next();
    }
  } else if (to.matched.some((record) => record.meta.requiresAuth)) {
    next("/login");
  } else {
    next();
  }
});
// Проверка при загрузке приложения
router.isReady().then(() => {
  const token = localStorage.getItem('token');
  // Если пользователь на главной странице и есть токен, перенаправляем на /buyers
  if (router.currentRoute.value.path === '/' && token) {
    router.push('/buyers');
  }
});

export default router;
