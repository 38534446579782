<template>
  <tr class="row" :class="index % 2 === 0 ? 'bg-white border-b hover:bg-gray-50' : 'bg-gray-50 border-b hover:bg-gray-100'">
    <!-- Display user name and project name -->



    <td class="px-6 py-2">
      <div :class="project.prevDaySpend>0?'nonzero':'bg-spend'" class="bg-spend"> {{ round(project.prevDaySpend) }}</div>
      <div :class="project.prevDayPrice>0?'bg-blue':'bg-price'"  > {{ round(project.prevDayPrice) }}</div>
    </td>
    <td class="px-6 py-2">
      <div :class="project.prevWeekSpend>0?'nonzero':'bg-spend'" class="bg-spend">{{ round(project.prevWeekSpend) }}</div>
      <div :class="project.prevWeekPrice?'bg-blue':'bg-pric'" > {{ round(project.prevWeekPrice) }}</div>
    </td>
    <td class="px-6 py-2">
      <div :class="project.prevMonthSpend>0?'nonzero':'bg-spend'" class="bg-spend"> {{ round(project.prevMonthSpend) }}</div>
      <div :class="project.prevMonthPrice>0?'bg-blue':'bg-price'">{{ round(project.prevMonthPrice) }}</div>
    </td>
    <td class="px-6 py-2">
      <div :class="project.currentWeekSpend>0?'nonzero':'bg-spend'" class="bg-spend">{{ round(project.currentWeekSpend) }}</div>
      <div :class="project.currentWeekPrice>0?'bg-blue':'bg-price'">{{ round(project.currentWeekPrice) }}</div>
    </td>
    <td class="px-6 py-2">
      <div :class="project.currentMonthSpend>0?'nonzero':'bg-spend'" class="bg-spend"> {{ round(project.currentMonthSpend) }}</div>
      <div :class="project.currentMonthPrice>0?'bg-blue':'bg-price'"> {{ round(project.currentMonthPrice) }}</div>
    </td>
  </tr>
</template>

<script setup>
/* eslint-disable */
const props = defineProps({
  project: Object,
  user: Object,
});

 // Удаляет первый элемент из массива

const round = (value) => {
  return Number(value).toFixed(2);
};
</script>

<style scoped>
th {
  cursor: pointer;
}

.row td:nth-last-child(1){
  border-bottom: none;
}

.row{
  display: flex;
  flex-direction: row;


}

td{
  display: flex;
  flex-direction: column;
    width: 110px !important;
    font-size: 12px;
    justify-content: center;
    align-items: center;
    padding-top: 0px !important;
    padding-bottom: 0px ;
    gap:3px
  }
  @media only screen and (max-width: 1800px) {
    
td{
    width: 100px !important;
    font-size: 9px;
  }
  }
.row td:nth-child(odd){
  background: #FFFFFF;
}

.bg-spend{
 color:rgb(220 38 38 / var(--tw-text-opacity))

}

.bg-price {
}

.nonzero{
color: rgb(22 163 74 / var(--tw-text-opacity));
}

.bg-blue{
  color: rgb(37 99 235 / var(--tw-text-opacity));
}


</style> 