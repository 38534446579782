export function cleanAndSplitArrayIntoChunks(arr, chunkSize = 7) {
  // Удаляем пустые строки
  const cleanedArray = arr.filter(item => {
    if (Array.isArray(item)) {
      return item.some(subItem => subItem !== '' && subItem !== null && subItem !== undefined);
    }
    return item !== '' && item !== null && item !== undefined; // Убираем пустые строки и значения null/undefined
  });

  // Разделяем массив на части по chunkSize элементов
  const result = [];
  for (let i = 0; i < cleanedArray.length; i += chunkSize) {
    result.push(cleanedArray.slice(i, i + chunkSize));
  }

  return result;
}

export function transformData(data) {

  const processPeriods = (periods) => {
    periods.shift(); // Удаляем первый элемент, т.к. это заголовок
    let chunked = [];
    for (let i = 0; i < periods.length; i += 2) {
      chunked.push({
        spend: periods[i] ? parseFloat(periods[i].replace(",", ".")) || 0 : 0,  // Преобразуем spend в число
        price: periods[i + 1] ? parseFloat(periods[i + 1].replace(",", ".")) || 0 : 0  // Преобразуем price в число
      });
    }
  
    return chunked;
  };

  return data.map(item => {
    // Первый элемент массива - это имя покупателя
    const buyer = {
      name: item[0][0], // Имя покупателя всегда первый элемент первого массива
      projects: []
    };

    // Обработка периодов (таблиц с показателями)
    const periods = [
      processPeriods(item[2]), // Предыдущий день
      processPeriods(item[3]), // Предыдущая неделя
      processPeriods(item[4]), // Предыдущий месяц
      processPeriods(item[5]), // Текущая неделя
      processPeriods(item[6])  // Текущий месяц
    ];

    // Добавляем индекс периода для отслеживания активных проектов
    let periodIndex = 0;

    // Создаем проекты для покупателя
    item[0].slice(1).forEach((project) => {
      if (project !== "") { // Игнорируем пустые строки
        // Привязываем периоды к проекту только при наличии данных
        buyer.projects.push({
          name: project,
          prevDaySpend: periods[0][periodIndex]?.spend || 0,
          prevDayPrice: periods[0][periodIndex]?.price || 0,
          prevWeekSpend: periods[1][periodIndex]?.spend || 0,
          prevWeekPrice: periods[1][periodIndex]?.price || 0,
          prevMonthSpend: periods[2][periodIndex]?.spend || 0,
          prevMonthPrice: periods[2][periodIndex]?.price || 0,
          currentWeekSpend: periods[3][periodIndex]?.spend || 0,
          currentWeekPrice: periods[3][periodIndex]?.price || 0,
          currentMonthSpend: periods[4][periodIndex]?.spend || 0,
          currentMonthPrice: periods[4][periodIndex]?.price || 0
        });

        // Увеличиваем индекс только если данные добавлены
        periodIndex++;
      }
    });

    return buyer;
  });
}
