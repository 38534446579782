export async function fetchData(endpoint) {

  try {

    const token = localStorage.getItem('token'); // Получаем токен из localStorage



    const response = await fetch(`https://dashboard.saiditeam.com/api/${endpoint}`, {

      method: 'GET',

      mode: 'cors', // CORS запрос

      headers: {

        'Content-Type': 'application/json',

        'Authorization': `Bearer ${token}`, // Добавляем токен в заголовок Authorization

      },

    });



    if (!response.ok) {

      throw new Error(`HTTP error! Status: ${response.status}`);

    }



    const data = await response.json();

    return data;

  } catch (error) {

    console.error('Error fetching data:', error);

    throw error;

  }

}

